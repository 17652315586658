import React, { useEffect, FC } from 'react'
import Image from 'next/image'
import styles from './MaxBenefit.module.scss'
import cn from 'classnames'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Link from "next/link";
import dynamic from "next/dynamic";


type MaxBenefitProps = {
  animation?: string
  containerScaling?: string
}

const MaxBenefit:FC<MaxBenefitProps> = ({animation, containerScaling}) => {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <section id="maxBenefit" className={cn(styles.container, containerScaling)}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperTitle}>
          <p className={styles.subText}>The formula of</p>
          <h2 className={styles.title}>
            <span className={styles.bold}>MAX.</span> benefit
          </h2>
        </div>
        <ul className={styles.list}>
          <li
            // data-aos={animation}
            // data-aos-delay="300"
            // data-aos-duration="1000"
            className={cn(styles.item, styles.activeBlock)}
          >
            <span className={styles.image}>
              <Image
                src="/images/aboutUs/benefit1.png"
                alt=" Premium Quality card"
                width={834}
                height={400}
              />
            </span>
            <div className={styles.wrapperText}>
              <h3 className={styles.header}>Premium Quality</h3>
              <p className={styles.text}>
                You get premium quality food supplements only,
                formulated by leading nutritionists
              </p>
            </div>
          </li>

          <li
            // data-aos={animation}
            // data-aos-delay="300"
            // data-aos-duration="1000"
            className={cn(styles.item, styles.activeBlock1)}
          >
            <div className={cn(styles.wrapperText, styles.wrapperTextSecond)}>
              <h3 className={styles.header}>Smart Price</h3>
              <p className={styles.text}>
                Pay smart prices and reap all the benefits of well-being without financial challenges.
              </p>
            </div>
            <span className={styles.image}>
              <Image
                src="/images/aboutUs/benefit2.png"
                alt=" Club Smart Price card"
                width={834}
                height={400}
              />
            </span>
          </li>
          <li
            // data-aos={animation}
            // data-aos-delay="300"
            // data-aos-duration="1000"
            className={cn(styles.item, styles.activeBlock2)}
          >
            <span className={styles.image}>
              <Image
                src="/images/aboutUs/benefit3.png"
                alt=" Move to Your Health Goalscard"
                width={834}
                height={400}
              />
            </span>
            <div className={styles.wrapperText}>
              <h3 className={styles.header}>Move to Your Health Goals</h3>
              <p className={styles.text}>
                Get everyday motivation to reach your nutritional goals in our
                app.
              </p>
              <div className={styles.wrapperBlock}>
                <a href="https://apps.apple.com/us/app/vitaliv-club/id1611704943">
                  <span className={styles.img1}>
                    <Image
                      src="/images/aboutUs/appStore.svg"
                      alt="App Store"
                      width={173}
                      height={53}
                    />
                  </span>
                </a>
                <a href="https://play.google.com/store/apps/details?id=us.vitaliv">
                  <span className={styles.img2}>
                    <Image
                      src="/images/aboutUs/googlePlay.svg"
                      alt="Google Play"
                      width={173}
                      height={53}
                    />
                  </span>
                </a>
              </div>
            </div>
          </li>
        </ul>

        <div className={styles.wrapperButton}>
            <Link href='/vitalivclub'>
                <button className={styles.button} type='button'>More about Vitaliv Club</button>
            </Link>
        </div>
      </div>
    </section>
  )
}

export default MaxBenefit
